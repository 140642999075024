import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/home/home.module').then(mod => mod.HomeModule),
  },
  {
    path: 'about-us',
    loadChildren: () => import('./modules/about/about.module').then(mod => mod.AboutModule),
  },
  {
    path: '**',
    loadChildren: () => import('./modules/404/not-found.module').then(mod => mod.NotFoundModule),
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
